// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-elevhalsa-js": () => import("./../../../src/pages/elevhalsa.js" /* webpackChunkName: "component---src-pages-elevhalsa-js" */),
  "component---src-pages-gymnasiet-js": () => import("./../../../src/pages/gymnasiet.js" /* webpackChunkName: "component---src-pages-gymnasiet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-newsletter-success-js": () => import("./../../../src/pages/newsletter-success.js" /* webpackChunkName: "component---src-pages-newsletter-success-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-skola-js": () => import("./../../../src/pages/skola.js" /* webpackChunkName: "component---src-pages-skola-js" */)
}

